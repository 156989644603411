<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>赛区信息管理</el-breadcrumb-item>
      <el-breadcrumb-item>赛区信息详情</el-breadcrumb-item>
    </el-breadcrumb>
    <el-page-header @back="goBack"></el-page-header>
    <!-- 筛选 -->
    <el-card style="margin-top: 15px">
      <el-row class="title_row">
        <el-col>
          <span class="title_class">赛区信息</span>
        </el-col>
      </el-row>
      <el-form
        :model="queryForm"
        class="filter_Form"
        label-width="120px"
        :rules="queryFormRules"
        ref="queryFormRef"
      >
        <el-row :gutter="0">
          <el-col :span="8">
            <el-form-item label="赛区名称" prop="districtName">
              <el-input
                v-model="queryForm.districtName"
                placeholder="请输入"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="赛区编号" prop="districtNo">
              <el-input
                v-model="queryForm.districtNo"
                placeholder="请输入"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="赛区电话" prop="districtTel">
              <el-input
                v-model="queryForm.districtTel"
                placeholder="请输入"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="0">
          <el-col :span="8">
            <el-form-item label="赛区类型" prop="divisionType">
              <el-select
                placeholder="请选择"
                v-model="queryForm.divisionType"
                style="width: 100%"
              >
                <el-option
                  v-for="item in divisionType"
                  :key="item.dictCode"
                  :label="item.dictLabel"
                  :value="item.dictValue"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="运营类型" prop="operationType">
              <el-select
                placeholder="请选择"
                v-model="queryForm.operationType"
                style="width: 100%"
              >
                <el-option
                  v-for="item in operationType"
                  :key="item.dictCode"
                  :label="item.dictLabel"
                  :value="item.dictValue"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="赛区负责人" prop="principal">
              <el-select
                placeholder="请选择"
                v-loadMore="loadMore"
                v-model="queryForm.principal"
                style="width: 100%"
              >
                <el-option
                  v-for="item in principalList"
                  :key="item.id"
                  :label="item.userName"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="0">
          <el-col :span="8">
            <el-form-item label="联系电话" prop="contactPhone">
              <el-input
                v-model="queryForm.contactPhone"
                placeholder="请输入"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="邮箱" prop="contactEmail">
              <el-input
                v-model="queryForm.contactEmail"
                placeholder="请输入"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="初赛晋级分数线" prop="earlyMatch">
              <el-input
                v-model="queryForm.earlyMatch"
                placeholder="请输入"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="0">
          <el-col :span="8">
            <el-form-item label="复赛晋级分数线" prop="repeatedMatch">
              <el-input
                v-model="queryForm.repeatedMatch"
                placeholder="请输入"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="赛区地址" prop="districtAddress">
              <el-input
                v-model="queryForm.districtAddress"
                placeholder="请输入"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="赛区介绍" prop="districtDescription">
              <el-input
                v-model="queryForm.districtDescription"
                placeholder="请输入"
                type="textarea"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="0">
          <el-col :span="8">
            <el-form-item label="包含赛程" prop="raceScheduleTypeList">
              <el-select
                v-model="queryForm.raceScheduleTypeList"
                placeholder="请按顺序选择"
                multiple
                @change="$forceUpdate()"
                type="textarea"
                clearable
                style="width: 100%"
                ><el-option
                  v-for="item in raceScheduleList"
                  :key="item.scheduleSort"
                  :label="item.scheduleName"
                  :value="item.id + ''"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="当前赛程" prop="raceScheduleIng">
              <el-select
                v-model="queryForm.raceScheduleIng"
                placeholder="请选择当前赛程"
                clearable
                style="width: 100%"
                ><el-option
                  v-for="item in raceScheduleList"
                  :key="item.scheduleSort"
                  :label="item.scheduleName"
                  :value="item.id + ''"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-row type="flex" justify="center">
        <el-col :span="4">
          <el-button
            type="primary"
            style="width: 100%"
            @click="saveDistrictInfoClicked"
            >保存赛区信息</el-button
          >
        </el-col>
      </el-row>
    </el-card>
  </div>
</template>

<script>
import { saveOrUpdateDistrict, queryErpUserList } from '@/http/api'
export default {
  data () {
    return {
      principalList: [],
      divisionType: this.$userInfo.divisionType(),
      operationType: this.$userInfo.operationType(),
      queryForm: JSON.parse(this.$route.query.districtData),
      queryFormRules: {
      },
      raceScheduleList: [],
      userInfoTotalPages: 0,
      getUserListForm: {
        pageNum: 1,
        pageSize: 10
      }
    }
  },
  created () {
    if (this.queryForm.id) {
      // console.log('raceScheduleTypeList', this.queryForm.raceScheduleType.split(','))
      this.queryForm.raceScheduleTypeList = this.queryForm.raceScheduleType.split(',')
    } else {
      // 需求：赛区包含赛程默认全赛程不可修改
      this.queryForm.raceScheduleTypeList = ['1', '2', '3', '4']
    }
    this.getDistrictData()
    this.getQueryErpUserList()
    this.raceScheduleList = this.$chnEngPublicRequest.querySeasonRaceSchedule(this, { activityType: '1' }).then(value => { this.raceScheduleList = value })
  },
  methods: {
    goBack () {
      this.$router.back()
    },
    // 获取赛区信息
    getDistrictData () {
      // queryDistrictPage().then((res) => {
      //   console.log('districtInfoData', res)
      //   this.queryForm = res.data
      // }).catch((err) => {
      //   console.log('err', err)
      // })
    },
    loadMore () {
      if (this.getUserListForm.pageNum + 1 >= this.userInfoTotalPages) return
      this.getUserListForm.pageNum++
      // 获取列表数据的方法
      this.getQueryErpUserList()
    },
    // 获取系统人员列表
    getQueryErpUserList () {
      queryErpUserList(this.getUserListForm).then((res) => {
        this.userInfoTotalPages = res.data.total
        this.principalList = [...this.principalList, ...res.data.list]
      }).catch((err) => {
        console.log('err', err)
      })
    },
    // 保存赛区信息
    saveDistrictInfoClicked () {
      this.queryForm.raceScheduleType = this.queryForm.raceScheduleTypeList.toString()
      saveOrUpdateDistrict(this.queryForm).then((res) => {
        console.log('saveOrUpdateDistrict', res)
        this.$message.success('保存成功')
      }).catch((err) => {
        console.log('err', err)
      })
    }
  }
}
</script>

<style>
</style>
